<template>
    <div id="carl">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" width="auto">
            <van-swipe-item><img src="../assets/ban1.png" alt=""></van-swipe-item>
            <van-swipe-item><img src="../assets/ban1.png" alt=""></van-swipe-item>
            <van-swipe-item><img src="../assets/ban1.png" alt=""></van-swipe-item>
            <van-swipe-item><img src="../assets/ban1.png" alt=""></van-swipe-item>
        </van-swipe>
    </div>
</template>
<script>
import { Swipe, SwipeItem } from 'vant';
export default {
    
}
</script>
<style>
#carl{
    width: 22rem;
    overflow: hidden;
    border-radius: 1rem;
}
.my-swipe .van-swipe-item {
    /* 这到时候取消靠图片撑开就行 */
    height: 10rem;
    color: #fff;
    font-size: 20px;
    text-align: center;
    background-color: #39a9ed;
}
.my-swipe .van-swipe-item img{
    width: 100%;
    height: 100%;
}
</style>